import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';

import { DefaultContext } from 'Context/DefaultContext';
import { StateContext } from 'context/StateContext';

import Instagram from 'Icons/Instagram';
import Twitter from 'Icons/Twitter';
import Facebook from 'Icons/Facebook';
import YouTube from 'Icons/YouTube';

import FooterComponent from 'Components/footer';
import Link from 'Components/link';
import ChatLink from 'Components/chat-link';

import { generateRandomId, scrollToTop } from 'Utils';
import colors from 'Utils/theme';
import { responsiveFont } from 'Styles/helpers';

const Privacy = styled.div`
  padding: 15px 0;

  a {
    ${responsiveFont({ sizes: '12,16' })};
  }
`;

const StyledFooter = styled.div`
  /* border-top: 2px solid ${colors.inHome.medGrey}; */
`;

const Footer = ({ style }) => {
  const { isAuthenticated, walmartURL, walmartPlusCheckoutURL } = useContext(DefaultContext);
  const { userState } = useContext(StateContext);

  const { hasInHome } = userState;

  const router = useRouter();

  const chatUser = localStorage.getItem('chatUser') || generateRandomId(18);
  localStorage.setItem('chatUser', chatUser);

  return (
    <>
      <StyledFooter style={style}>
        <FooterComponent
          copyright={`${new Date().getFullYear()} Walmart. All Rights Reserved`}
          privacy={
            <Privacy>
              <Link rel="noopener noreferrer" href="https://corporate.walmart.com/privacy-security/walmart-privacy-policy">
                Privacy policy
              </Link>
              <Link href="https://www.walmart.com/help/article/inhome-terms-of-use-in-home-delivery/0bd3139cc6234876916ac7b7012626a6">Terms of use</Link>
            </Privacy>
          }
          social={
            <>
              <Link title="Facebook" rel="noopener noreferrer" href="http://www.facebook.com/walmart">
                <Facebook fill={colors.walmart.blue} />
              </Link>
              <Link title="Instagram" rel="noopener noreferrer" href="http://instagram.com/walmart">
                <Instagram fill={colors.walmart.blue} />
              </Link>
              <Link title="Twitter" rel="noopener noreferrer" href="https://twitter.com/Walmart">
                <Twitter fill={colors.walmart.blue} />
              </Link>
              <Link title="YouTube" rel="noopener noreferrer" href="https://www.youtube.com/walmart">
                <YouTube fill={colors.walmart.blue} />
              </Link>
            </>
          }
          contact={
            <>
              <ChatLink>Live chat with us</ChatLink>
            </>
          }
          ccpa={
            <>
              <a rel="noreferrer noopener nofollow" target="_blank" href={`${process.env.NEXT_PUBLIC_CCPA_URL}/affirmation?brandCode=WMT&requestType=OPTOUT`}>
                Do not sell my personal information
              </a>
              <a rel="noreferrer noopener nofollow" target="_blank" href={`${process.env.NEXT_PUBLIC_CCPA_URL}/affirmation?brandCode=WMT&requestType=ACCESS`}>
                Request my personal information
              </a>
            </>
          }
        >
          <Link href="/" clickFunction={() => router.pathname === '/' && scrollToTop(800)} track>
            Overview
          </Link>

          <Link href="https://www.walmart.com/plus/inhome-frequently-asked-questions" clickFunction={() => router.pathname === '/fyi' && scrollToTop(800)} track>
            FAQ
          </Link>
          {isAuthenticated && hasInHome ? (
            <Link href={`${walmartURL}/account`} targetBlank track>
              Account
            </Link>
          ) : null}
          {!hasInHome && (
            <Link href={walmartPlusCheckoutURL} title="Get InHome" track>
              Get InHome
            </Link>
          )}
        </FooterComponent>
      </StyledFooter>
    </>
  );
};

export default Footer;
